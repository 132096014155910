.selectors {
    background-image: linear-gradient(45deg, transparent 50%, white 50%), linear-gradient(135deg, white 50%, transparent 50%), linear-gradient(to right, #3E4B65, #3E4B65);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
    background-color: #F2F4F3;
    border: thin solid #707070;
    border-radius: 15px;
    display: inline-block;
    font: inherit;
    line-height: 1.2em;
    padding: 0.5em 4.5em 0.5em 2em;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    color: #3E4B65;
    opacity: 1;
    width: auto;
    height: fit-content;
    margin: 10px;
    /* margin: 10px; */
  }

.drop{
    background-position: calc(100% - 20px) calc(0.6em + 1px), calc(100% - 15px) calc(0.6em + 1px), 100% 0;
    background-size: 5px 5px, 5px 5px, 1.8em 2em;
    background-color: #FFFFFF;
    padding: 0.1em 3em 0.1em 0.7em;
    font: oblique normal normal 20px 'Open Sans'!important;
  }

.inputfield {
    padding: 0 20px;
    background: #3E4B65;
    border-radius: 15px;
    height: 37px;
    width: fit-content;
    border: 1px solid #707070;
    margin-top: 10px;
  }
  
.inputfield input {
    height: 100%;
    padding: 0 10px;
    background-color: #F2F4F3;
  }
  
.inputfield input:focus {
    outline: none;
}