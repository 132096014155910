.instruction-box {
    justify-self: center;
    background-color: rgba(0, 0, 0, 0.3);
    background-image: url('../resources/bg.png');
    position: relative;
    padding: 5px;
    width: 100%;
    border: 2px solid #b78846;
}

.instruction-box:before,
.instruction-box:after {
    content: "•";
    position: absolute;
    width: 14px;
    height: 14px;
    font-size: 14px;
    color: #b78846;
    border: 2px solid #b78846;
    line-height: 12px;
    top: 5px;
    text-align: center;
}

.instruction-box:before {
    left: 5px;
}

.instruction-box:after {
    right: 5px;
}

.instruction-box .instruction-box-inner {
    position: relative;
    border: 2px solid #b78846;
    padding: 40px;
}

.instruction-box .instruction-box-inner:before,
.instruction-box .instruction-box-inner:after {
    content: "•";
    position: absolute;
    width: 14px;
    height: 14px;
    font-size: 14px;
    color: #b78846;
    border: 2px solid #b78846;
    line-height: 12px;
    bottom: -2px;
    text-align: center;
}

.instruction-box .instruction-box-inner:before {
    left: -2px;
}

.instruction-box .instruction-box-inner:after {
    right: -2px;
}

.instruction-box .instruction-box-inner .wraper {
    padding: .7em 2em;
}