/*Give the item-card class a border and a margin*/
.item-card {
  margin: 1vh;
}

.filters {
  justify-content: center;
}

.pagination{
  display: flex;
  justify-content: center;

  margin: 15px;
}

.page-item{
  cursor: pointer;
}


.create-btn {
  color: white;
  background: #9B2915 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  font: normal normal bold 20px 'Scarlet Wood';
  font-family: 'scarlet-wood';
  height: 36px !important;
  border-radius: 15px !important;
  line-height: 25px !important;
  margin-top: 10px;
  min-width: 80px;
}

.create-btn:active{
  background-color: #707070;
}

.inputfield {
  padding: 0 20px;
  background: #3E4B65;
  border-radius: 15px;
  height: 37px;
  width: fit-content;
  border: 1px solid #707070;
  margin-top: 10px;
}

.inputfield input {
  height: 100%;
  padding: 0 10px;
  background-color: #F2F4F3;
}

.inputfield input:focus {
  outline: none;
}

.boxes {
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  max-height: 35rem;
}

.magic-box {
  background-image: url('../resources/images/magiccard-background.png');
  background-size: contain;
  height: 215px;
  background-repeat: no-repeat;
  width: 365px;
  text-align: center;
  position: relative;
}

.box-title {
  margin-top: 20px;
  color: #011638;
  font: normal normal normal 25px 'carta-marina';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box-sub-title {
  color: #205F83;
  font: oblique normal normal 18px/20px 'tablet-gothic';
  opacity: 1;
}

.box-description {
  font: normal normal normal 17px/30px 'adobe-jenson-pro';
  margin-top: 20px;
  padding: 0 30px;
  line-height: 22px;
  text-align: justify;
}

.open-details {
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}

.share-active {
  display: none;
}

.open-details:hover .share-inactive {
  display: none;
}

.open-details:hover .share-active {
  display: block !important;
}

.open-details img {
  height: 40px;
}

