.content{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 9vh;
    z-index: 2;
    height: 91vh;
    position: relative;
    display:block;
    padding-top: 2em;
}

/* set the createButton to the right of the page */
#create-button{
    position: absolute;
    right: 0;
    top: 0;
    margin: 1em;

}

div.scroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: 75vh;
    scrollbar-width: none;
    padding: 1%;
    margin: 1%;
}
div.searchBar{
    margin: 1em;
    border-radius: 20px;
    background-color: #3E4B65;
    border-left: 5px solid #3E4B65;
    border-right: 5px solid #3E4B65;
    border-top: 1px solid #3E4B65;
    border-bottom: 1px solid #3E4B65;
}
input.search{
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 20px;
}