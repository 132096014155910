/*Right align all the elements in the navbar and keep the navbar at the top*/
@import url("https://use.typekit.net/row3cpy.css");
/* import the font adobe-jenson-pro from typekit */


.navbar {
  /* justify-content: flex-end; */
  align-items: center;
  background: #011638 !important;
  border-bottom: 4px solid #205F83;
  position: fixed;
  font-family: "scarlet-wood"
    , serif;
  z-index: 3;
}

.navbar a {
  color: #449DD1;
  text-decoration: none;
  margin: 0 0.25em;
  font-size: 1.2em;
  margin-bottom: -10px;
}

.navbar a:hover {
  color: #9B2915;
}

.navbar a:active {
  color: #fff !important;

}

.navbar a.active{
  color: #fff!important;
}

.navbar .logo-img {
  height: 2.5em;
  margin: 0 0.1em;
  padding: 0 0 0 0;
}

.logo-text {
  /* display: contents; */
  padding: 10px 0;
  font-size: 1.5rem;
}

.navbar .logo {
  color: #9B2915;
  font-family: "nelson-rugged", serif;
  margin: 0 2.5em 0 0;
  padding: 0 0 0 0;
  display: flex;
}

.navbar .menuLink {
  color: #9B2915;
}

.navbar .menuLink:hover {
  color: #449DD1;
  background-color: none;
}

.settings{
  position: absolute;
  right: 12px;
  top: 30%;
  background: #205F83;
}

.active-menu {
  text-align: right;
  display: inline;
  color: #205F83;
  position: absolute;
  width: 120px;
  height: 215px;
  overflow: hidden;
  border-top: 1px solid #707070;
  right: 12px;
  top: 30%;
}

.settings-sub {
  cursor: pointer;
  display: flex;
  color: #F2F4F3;
  justify-content: center;
  padding: 10px;
}

.settings-sub-collpase{
  position: absolute;
  right: 0;
  top: 35%;
  cursor: pointer;
  display: flex;
  color: #205F83;
  justify-content: end;
  margin-right: 20px;
}

.settings-list{
  cursor: pointer;
  color: #F2F4F3;
  justify-content: center;
  text-align: center;
  font-family: 'adobe-jenson-pro';
  font-size: 16px;
}

.settings-list div{
  border-top: 1px solid black;
  padding: 10px;
}

.active-menu::after,
.active-menu::before {
  content: '';
  position: absolute;
  top: -3px;
  height: 100%;
  width: 50%;
  z-index: -1;
  border: 1px solid #707070;
  box-sizing: border-box;
  background-color: #205F83;
  opacity: 1;
  animation: fade 1s linear;
}

/* @keyframes fade {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
} */

.active-menu::before {
  left: 0;
  transform-origin: 0 0;
  transform: skewY(-20deg);
  border-width: 0 0 1px 1px;
}

.active-menu::after {
  right: 0;
  transform-origin: 100% 0;
  transform: skewY(20deg);
  border-width: 0 1px 1px 0;
}

.inline {
  display: inline;
  padding: 2px 2px;
}

.list-first-option:hover{
 color: #011638;
}

.list-last-option{
  border-bottom: 1px solid black;
}

.list-last-option:hover{
  color: #011638;
}

@media only screen and (max-width: 991px) {
  #menulist {
    display: none;
  }
}