/*Create a css for the chat list */
div .npc {
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    vertical-align: center;
    justify-content: space-between;
    cursor: pointer;
}
.chat{
    border: solid 2px #011638;
    border-radius: 10px;
    background-color: transparent;
    margin: 2px 0px 2px 0px;
    padding: 2px 2px 2px 2px;
}
.chat:hover{
    background-color: rgba(110, 144, 117, 0.9);
}
.chat:active{
    background-color: rgba(27, 73, 101, 1);
    color:#fff;
}
section .npc {
    display: flex;
    flex-direction: column;
    align-items: right;
    margin-top: 5%;
    word-wrap: break-word;
    text-align: center;
}

.npc .avatar  {
    display: block;
    width: 60px;
    height: 60px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
