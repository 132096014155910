.app-modal {
    height: 500px;
    width: 92%;
    background-color: #FBF7EF;
    border: 2px solid #011638;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
  }
  
  .delete {
    position: absolute;
    top: -10px;
    height: 30px;
    z-index: 2;
    right: -8px;
    cursor: pointer;
  }
  
  .modal-container {
    height: 96%;
    width: 96%;
    border: 2px solid #011638;
  }
  
  .modal-container .title {
    padding: 10px;
    text-align: center;
    font: normal normal normal 35px/41px 'carta-marina';
  }

  .modal-container .title-edit {
    padding: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    font: normal normal normal 35px/41px 'carta-marina';
  }

  .title-edit .first{
    background: #3E4B65;
    padding: 0 16px;
    border-radius: 12px;
    font-size: 30px;
    border: 1px solid #707070;
  }

  .title-edit .first .last{
    background: white;
    padding: 0 22px;
  }
  
  .modal-container .sub-title {
    margin: 0 25px;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    padding: 5px;
    color: #011638;
    font: normal normal normal 20px/28px 'carta-marina';
  }

  .modal-container .sub-title-edit {
    margin: 0 25px;
    padding: 5px;
    color: #011638;
    font: normal normal normal 20px/28px 'carta-marina';
  }
  
  .modal-container .model-body{
    height: auto;
    width:auto;
    padding: 25px;
    font: normal normal normal 18px 'adobe-jenson-pro';
    color: #011638;
    line-height: 2;
  }
  
  .modal-container .edit{
    position: absolute;
    bottom: 20px;
    right: 30px;
    border: 1px solid #707070;
    border-radius: 20px;
    width: 95px;
    background-color: #3E4B65;
    font: normal normal bold 17px/33px "scarlet-wood";
    color: #F2F4F3;
  }

  .modal-container button.save{
    background-color: #9B2915;
  }

  .modal-container button.cancel{
    right: 150px;
    background-color: #3E4B65;
  }

  .display-none{
    display: none!important;
  }

  /* .drop{
    background-position: calc(100% - 20px) calc(0.6em + 1px), calc(100% - 15px) calc(0.6em + 1px), 100% 0;
    background-size: 5px 5px, 5px 5px, 1.8em 2em;
    background-color: #FFFFFF;
    padding: 0.1em 3em 0.1em 0.7em;
    font: oblique normal normal 20px 'Open Sans'!important;
  } */

  .modal-textarea{
    height: 254px;
    width: 100%;
    border: 1px solid black;
    border-radius: 10px;
    padding: 15px;
  }

  .ad-container{
    top: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .middle-ads-container h2{
    font: normal normal bold 60px/72px 'scarlet-wood';
    color: #011638;
  }

  .error{
    color: red;
  }