/* set the main class to center the content */
.main {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 7.5vh;
    background-color: rgba(251, 247, 239, 0.8);
    z-index: 2;
    height: 91vh;
    position: relative;
    display: block;
    padding-top: 2em;
}

.layout .nav-area {
    z-index: 1;
}

body {
    background-size: cover;
    background-image: url("../resources/images/background_castle_wide.png") !important;
    /* set the background image to be full screen*/
    overflow-x: hidden;
    background-repeat: none;
}

.middle-ads-container {
    background: white;
    height: 75px;
    width: 60%;
    border: 1px solid #707070;
    opacity: 1;
    margin: 0;
    position: absolute;
    left: 20%;
    top: 0;
    text-align: center;
}