
.button-18 {
  align-items: center;
  background-color: #0A66C2;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}

.button-18:hover,
.button-18:focus { 
  background-color: #16437E;
  color: #ffffff;
}

.button-18:active {
  background: #09223b;
  color: rgb(255, 255, 255, .7);
}

.button-18:disabled { 
  cursor: not-allowed;
  background: rgba(0, 0, 0, .08);
  color: rgba(0, 0, 0, .3);
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
 * {
	 margin: 0;
	 padding: 0;
	 border: 0;
	 box-sizing: border-box;
}
 *:before, *:after {
	 box-sizing: border-box;
}
 html, body {
	 height: 100%;
	 background: radial-gradient(#003, #000);
	 overflow: hidden;
	 font-family: 'Open Sans';
   display: block;
	
}
 h6 {
	 font-weight: 700;
	 margin-bottom: 0.5em;

   text-size-adjust: 80%;
}
 .box {
	 background-color: rgba(0, 0, 0, 0.3);
	 position: relative;
	 padding: 5px;
   color: #fff;
   width: 100%;
	 border: 2px solid #b78846;
   height: 30vh;
}
 .box:before, .box:after {
	 content: "•";
	 position: absolute;
	 width: 14px;
	 height: 14px;
	 font-size: 14px;
	 color: #b78846;
	 border: 2px solid #b78846;
	 line-height: 12px;
	 top: 5px;
	 text-align: center;
}
 .box:before {
	 left: 5px;
}
 .box:after {
	 right: 5px;
}
 .box .box-inner {
	 position: relative;
	 border: 2px solid #b78846;
	 padding: 40px;
   height: calc(30vh - 14px);
}
.box:hover{
  border: 2px solid #0A66C2;
}
 .box .box-inner:before, .box .box-inner:after {
	 content: "•";
	 position: absolute;
	 width: 14px;
	 height: 14px;
	 font-size: 14px;
	 color: #b78846;
	 border: 2px solid #b78846;
	 line-height: 12px;
	 bottom: -2px;
	 text-align: center;
}
 .box .box-inner:before {
	 left: -2px;
}
 .box .box-inner:after {
	 right: -2px;
}

.box .box-inner .wraper{
  padding: .7em 2em;
}

.wraper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.shadow {  
  display: inline;
  background: rgba(11, 11, 0, .8);
  line-height: 1.3em;
  padding: .1em;
  color: #fff;
  font-size: 2.5vw;
  box-shadow: .3em 0 0 #111, -.3em 0 0 #111;

}
#magicItem{
  background: #000 url('../resources/images/MagicItem.png') no-repeat center center;
  background-size:     cover; 
}
#NPC{
  background: #000 url('../resources/images/NPC.png') no-repeat center center;
  background-size:     cover; 
}

#Village{
  background: #000 url('../resources/images/Village.png') no-repeat center center;
  background-size:     cover; 
}

/*Evenly distribute the elements with the box class across the contianer.*/
.box-container {
  display: flex;
  float:left;
  flex-wrap: wrap;
  justify-content: space-around;
}
/*The box class is used to create the boxes that will be displayed on the page.*/
.box {
  transition: all 0.3s ease;
}

.left-margin{
  margin-left: 1em;
}
#generators{
  padding: 2em 2em 2em 2em;
  justify-items: center;
  display: flex;
  justify-content: center;
  height: 30vh;
  margin: 50px 0;
}
@media screen and (max-width: 930px) {
  .box-container {
    flex-direction: column;
  }
  .box {
    width: 100%;
  }
}
#card-items{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5vh;
  height: 100%;


}
#card-items .card{
  background-color: #fff;
  width: 100%;
  height: 100%;
  border: 2px solid #b78846;
  border-radius: 10px;
  box-shadow: 0 0 10px #b78846;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  margin: 1em;
  transition: all 0.3s ease;
  
}