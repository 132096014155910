@import url("https://use.typekit.net/row3cpy.css");

body,
html,div#root {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}

body {
    background-repeat: no-repeat;
    background-size: cover;
}

.form_outer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}


.form_group h1 {
    margin: 0 0 30px;
    font-family: "carta-marina";
    font-weight: normal;
    font-size: 50px;
    color: #011638;
    text-align: center;
    line-height: 57px;
}

.form_inner {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    max-width: 1236px;
    background: #FBF7EFE6 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    align-items: center;
    box-sizing: border-box;
}

.image_side {
    width: 40%;
    text-align: center;
}

.form_side {
    width: 60%;
    text-align: center;
}

.form_fils .form-control {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 5px;
    outline: none;
    height: 49px;
    padding: 0 15px;
    width: 100%;
    max-width: 450px;
    font-family: "adobe-jenson-pro";
    font-size: 20px;
    line-height: 28px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #011638;
    opacity: 1;
    box-sizing: border-box;
}

.form_fils {
    width: 100%;
    display: inline-block;
    margin-bottom: 25px;
}

.form_fils .form-control::-webkit-input-placeholder {
    color: #011638;
    opacity: 0.6;
}

.form_fils .form-control:-ms-input-placeholder {
    color: #011638;
    opacity: 0.6;
}

.form_fils .form-control::placeholder {
    color: #011638;
    opacity: 0.6;
}
.form_btn .btn.active-button {
    background: #9B2915 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
}

.form_btn .btn {
    background: #3E4B65 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 20px;
    height: 50px;
    letter-spacing: 0px;
    color: #F2F4F3;
    opacity: 1;
    font-family: "scarlet-wood";
    font-size: 28px;
    line-height: 34px;
    width: 100%;
    max-width: 176px;
    cursor: pointer;
}


.form_btn .btn:hover {
    background:#011638;
    color:#9B2915
}

.form_btn {

    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 13%;
    margin: 0 auto 0;
    width: 100%;
    max-width: 450px;
}

.image_side {
    position: relative;
}

@media only screen and (min-width: 768px) {
    .image_side::after {
        content: "";
        position: absolute;
        right: 0;
        height: 100%;
        width: 4px;
        background: #205F83;
        top: 0;
    }
}




@media only screen and (min-width: 1025px) and (max-width: 1599px) {

    .image_side,
    .form_side {
        padding: 30px 0;
    }

    .form_inner {
        max-width: 950px;
        padding: 60px 40px 80px;
    }

    .image_side img {
        max-width: 250px;
    }

    .image_side {
        padding-right: 40px;
    }

    .form_side {
        padding-left: 40px;
    }

    .form_group h1 {
        margin: 0 0 20px;
        font-size: 46px;
        line-height: 52px;
    }

    .form_btn .btn {
        font-size: 24px;
        max-width: 160px;
    }

    .form_btn {
        margin: 5px auto 0;
        width: 100%;
    }
}

@media only screen and (min-width: 1600px) {

    .image_side,
    .form_side {
        padding: 100px ;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .form_outer {
        padding: 0 35px;
    }

    .image_side img {
        max-width: 200px;
    }

    .form_group h1 {
        font-size: 42px;
        margin: 0 0 10px;
    }

    .form_side {
        padding-left: 50px;
        box-sizing: border-box;
    }

    .form_btn {
        margin-top: 0;
    }

    .form_btn .btn {
        font-size: 20px;
        max-width: 150px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .form_btn .btn {
        font-size: 20px;
        max-width: 130px;
    }

    .form_fils .form-control {
        font-size: 18px;
        height: 45px;
    }

    .form_side {
        padding-left: 40px;
    }

    .image_side {
        padding-right: 40px;
        box-sizing: border-box;
    }

    .image_side::after {
        height: 126%;
        top: -25px;
    }
}

@media only screen and (max-width:767px) {
    .image_side {
        width: 100%;
    }

    .form_group h1 {
        font-size: 32px;
        margin-bottom: 0;
    }

    .form_fils .form-control {
        font-size: 18px;
        height: 45px;
    }

    .form_btn .btn {
        font-size: 21px;
        max-width: 135px;
    }

    .form_btn {
        margin-top: 0;
    }

    .form_side {
        width: 100%;
    }

    .form_inner {
        max-width: 475px;
        width: 90%;
        padding: 30px 20px 50px;
        flex-wrap: wrap;
    }

    .image_side img {
        max-width: 120px;
        margin-bottom: 30px;
    }
}